import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import { ImgBackground } from '@wix/communities-image-lib';
import Link from '../link/internal-link';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withAuth from '../../hoc/with-auth';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import styles from './category-list-item-mobile.scss';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { doesUserHavePermissionsToSeeCategoryActions } from '../../containers/category-actions/category-actions';
import withPermissions from '../../hoc/with-permissions';

class CategoryListItem extends Component {
  render() {
    const {
      category = {},
      t,
      titleFontClassName,
      contentFontClassName,
      isViewsCountEnabled,
      showViewCount,
      can,
    } = this.props;
    const containerClassName = classNames(
      styles.container,
      'forum-card-background-color',
      'category-list-item',
    );
    const titleClassName = classNames(
      styles.title,
      titleFontClassName,
      'category-list-item__title',
    );
    const statisticsClassName = classNames(
      styles.statistics,
      contentFontClassName,
      'category-list-item__statistics',
    );
    const categoryLink = `/${category.slug}`;

    let categoryBadge = null;
    if (isPrivate(category)) {
      categoryBadge = <LockEmptyIcon className="forum-icon-fill" />;
    }

    return (
      <div className={containerClassName} data-hook="category-list-item">
        {category.coverType === COVER_TYPE_IMAGE && (
          <div className={styles.cover}>
            <Link className={styles.coverLink} to={categoryLink}>
              <ImgBackground
                className={styles.coverImage}
                src={category.cover}
                width={48}
                height={48}
              />
            </Link>
          </div>
        )}
        <div className={classNames(styles.details, 'forum-text-color')}>
          <div>
            <h2 className={titleClassName} data-hook="category-list-item__title">
              <Link className={styles.categoryLink} to={categoryLink}>
                {category.label}
              </Link>
            </h2>
            <p className={statisticsClassName}>
              <span data-hook="category-list-item__total-posts">
                {t('category-list-item.total-posts', { count: category.totalPosts || 0 })}
              </span>
              {isViewsCountEnabled && showViewCount && (
                <span data-hook="category-list-item__total-views">
                  {t('category-list-item.total-views', { count: category.totalViews || 0 })}
                </span>
              )}
            </p>
          </div>
          <div className={styles.buttonGroup}>
            {categoryBadge}
            {doesUserHavePermissionsToSeeCategoryActions(can, category) ? (
              <MoreButton size={MoreButton.SIZE_SMALL}>
                <CategoryActions category={category} />
              </MoreButton>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

CategoryListItem.propTypes = {
  category: PropTypes.object.isRequired,
  t: PropTypes.func,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isViewsCountEnabled: PropTypes.bool,
  showViewCount: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  showViewCount: !isCacheableRendering(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withPermissions,
  withFontClassName,
  withTranslate,
)(CategoryListItem);
