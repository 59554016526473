import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Interpolate } from 'react-i18next';
import CommentActions from '../../containers/comment-actions';
import CommentAvatar from '../comment-avatar-yak';
import MoreButton from '../more-button';
import TimeAgo from '../time-ago';
import TopComment from '../top-comment-yak';
import { BulletSeparator } from '../separator';
import styles from './comment-header.scss';
import withDeviceType from '../../hoc/with-device-type';
import AvatarImage from '../avatar-image';

const renderMoreButton = (
  doRender,
  comment,
  type,
  showMoreButtonEdit,
  showMoreButtonTopComment,
) => {
  const moreButtonId = `more-button-${comment._id}`;

  if (doRender) {
    return (
      <div className={classNames(styles.moreButton, styles[type])}>
        <MoreButton id={moreButtonId}>
          <CommentActions
            comment={comment}
            focusOnCloseId={moreButtonId}
            showEditButton={showMoreButtonEdit}
            showTopCommentButton={showMoreButtonTopComment}
          />
        </MoreButton>
      </div>
    );
  }
};

const CommentHeader = ({
  type,
  comment,
  showMoreButton,
  showTopComment,
  showEditedDate,
  isMobile,
  showMoreButtonEdit,
  showMoreButtonTopComment,
}) => (
  <div
    className={classNames(
      styles.commentHeader,
      styles[type],
      { [styles.withMoreButton]: showMoreButton },
      'comment-header',
    )}
  >
    {comment.owner && (
      <CommentAvatar
        user={comment.owner}
        date={comment.createdDate}
        type={AvatarImage.POST_PAGE}
        showDate={false}
      />
    )}
    <span className={styles.commentTime}>
      {showTopComment && <TopComment />}
      {!isMobile && showTopComment && <BulletSeparator />}
      {!isMobile && <TimeAgo time={comment.createdDate} />}
      {showEditedDate && comment.editedDate && <BulletSeparator />}
      {showEditedDate && comment.editedDate && (
        <Interpolate i18nKey="comment.edited" timeAgo={<TimeAgo time={comment.editedDate} />} />
      )}
    </span>
    {renderMoreButton(showMoreButton, comment, type, showMoreButtonEdit, showMoreButtonTopComment)}
  </div>
);

CommentHeader.SEARCH = 'search';

CommentHeader.defaultProps = {
  showTopComment: false,
  showMoreButtonEdit: true,
  showMoreButtonTopComment: true,
};

CommentHeader.propTypes = {
  type: PropTypes.string,
  comment: PropTypes.object.isRequired,
  showMoreButton: PropTypes.bool,
  showMoreButtonEdit: PropTypes.bool,
  showMoreButtonTopComment: PropTypes.bool,
  showTopComment: PropTypes.bool,
  showEditedDate: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default flowRight(withDeviceType)(CommentHeader);
