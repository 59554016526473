import React from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import forDevice from '../../../hoc/for-device';
import { ModalCentered, ModalFromRight, ModalHeader } from '../base';
import withFontClassName from '../../../hoc/with-font-class-name';
import withTranslate from '../../../hoc/with-translate';
import withSettingsColor from '../../../hoc/with-settings-color';
import styles from './manage-category-members-modal.scss';
import ButtonGroup from '../../button-group';
import SearchInput from '../../search-input';
import Header from '../../header';
import HeaderButton from '../../header-button';
import HeaderBodySeparator from '../base/header-body-separator';

const ManageCategoryMembersModalDesktop = props => {
  const {
    contentFontClassName,
    closeModal,
    titleTextKey,
    bottomCtaTextKey,
    manageGroupsLink,
    formSubmit,
    searchQueryValue,
    searchQueryOnChange,
    searchQueryCount,
    reducePollingInterval,
    children,
    t,
    disablePrimaryButton,
    groupsCount,
  } = props;
  return (
    <ModalCentered onClose={closeModal} width={ModalCentered.WIDTH_LARGE}>
      <div
        className={classNames(contentFontClassName, 'default-desktop-header-text-color')}
        data-hook="manage-category-members-modal"
      >
        <ModalHeader isLeftAligned>{t(titleTextKey)}</ModalHeader>
        <div className={styles.toolbar}>
          <SearchInput
            value={searchQueryValue}
            onChange={searchQueryOnChange}
            isExpanded={true}
            showClearButton
            inputClassName={classNames(contentFontClassName, 'color-5-text')}
            borderClassName="color-5-background"
            iconClassName="color-5-fill"
            showCount={true}
            count={searchQueryCount}
            isFullWidth={false}
          />
        </div>
        <div className={styles.content}>{children}</div>
        <div
          className={classNames(styles.footer, {
            [styles.maFooter]: manageGroupsLink === undefined,
          })}
        >
          {manageGroupsLink && groupsCount > 0 ? (
            <a
              onClick={reducePollingInterval}
              className={classNames(styles.boLink, 'button-color')}
              href={manageGroupsLink}
              target="_blank"
            >
              {t(bottomCtaTextKey)}
            </a>
          ) : (
            <div />
          )}
          <ButtonGroup
            isPrimaryButtonVisible={!disablePrimaryButton}
            primaryButtonText={t('manage-category-members-modal.done')}
            primaryButtonProps={{
              onClick: () => {
                formSubmit();
                closeModal();
              },
            }}
            secondaryButtonText={t('move-category-confirmation.cancel')}
            secondaryButtonProps={{ onClick: () => closeModal() }}
          />
        </div>
      </div>
    </ModalCentered>
  );
};

ManageCategoryMembersModalDesktop.propTypes = {
  contentFontClassName: PropTypes.string,
  borderColor: PropTypes.string,
  titleTextKey: PropTypes.string,
  manageGroupsLink: PropTypes.string,
  bottomCtaTextKey: PropTypes.string,
  closeModal: PropTypes.func,
  formSubmit: PropTypes.func,
  reducePollingInterval: PropTypes.func,
  searchQueryOnChange: PropTypes.func,
  searchQueryValue: PropTypes.string,
  searchQueryCount: PropTypes.number,
  groupsCount: PropTypes.number,
  disablePrimaryButton: PropTypes.bool,
};

const ManageCategoryMembersModalMobile = props => {
  const {
    contentFontClassName,
    closeModal,
    borderColor,
    formSubmit,
    searchQueryValue,
    searchQueryOnChange,
    searchQueryCount,
    children,
    t,
  } = props;

  return (
    <ModalFromRight>
      <div
        className={classNames(
          styles.mobileOuterContainer,
          contentFontClassName,
          'default-desktop-header-text-color',
        )}
        data-hook="manage-category-members-modal"
      >
        <Header
          left={
            <HeaderButton
              onClick={() => closeModal()}
              text={t('move-category-confirmation.cancel')}
              dataHook="leftButton"
            />
          }
          right={
            <HeaderButton
              onClick={() => formSubmit()}
              text={t('manage-category-members-modal.done')}
              dataHook="rightButton"
            />
          }
        />
        <HeaderBodySeparator />
        <div className={styles.mobileContentContainer}>
          <div className={styles.toolbar}>
            <SearchInput
              value={searchQueryValue}
              onChange={searchQueryOnChange}
              isExpanded={true}
              showClearButton
              inputClassName={classNames(contentFontClassName, 'color-5-text')}
              borderClassName="color-5-background"
              iconClassName="color-5-fill"
              showCount={true}
              count={searchQueryCount}
              isFullWidth
            />
          </div>
          <div className={styles.separator} style={{ borderColor }} />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </ModalFromRight>
  );
};

ManageCategoryMembersModalMobile.propTypes = {
  contentFontClassName: PropTypes.string,
  borderColor: PropTypes.string,
  closeModal: PropTypes.func,
  formSubmit: PropTypes.func,
  searchQueryOnChange: PropTypes.func,
  searchQueryValue: PropTypes.string,
  searchQueryCount: PropTypes.number,
};

const withHOCs = comp =>
  flowRight(
    withFontClassName,
    withTranslate,
    withSettingsColor({
      propName: 'borderColor',
      siteColorFallback: 'color-5',
      siteColorAlpha: 0.2,
      fallbackColor: 'rgba(51, 51, 51, 0.2)',
    }),
  )(comp);

export default forDevice(
  withHOCs(ManageCategoryMembersModalMobile),
  withHOCs(ManageCategoryMembersModalDesktop),
);
