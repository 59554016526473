import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import Link from '../link/internal-link';
import ArrowSubcategoriesIcon from '../icons/arrow-subcategories-icon';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withTranslate from '../../hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withAuth from '../../hoc/with-auth';
import styles from './subcategory-list-item.scss';
import { isPrivate } from '../../../common/services/category-privacy-utils';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { doesUserHavePermissionsToSeeCategoryActions } from '../../containers/category-actions/category-actions';
import withPermissions from '../../hoc/with-permissions';

class SubcategoryListItem extends Component {
  render() {
    const {
      category = {},
      t,
      titleFontClassName,
      contentFontClassName,
      borderWidth,
      isViewsCountEnabled,
      showViewCount,
      can,
    } = this.props;
    const containerClassName = classNames(
      styles.container,
      'forum-card-background-color',
      'forum-card-border-color',
      'category-list-item',
    );
    const titleClassName = classNames(
      styles.title,
      titleFontClassName,
      'category-list-item__title',
    );
    const statisticsClassName = classNames(
      styles.statistics,
      contentFontClassName,
      'category-list-item__statistics',
    );
    const style = { borderWidth };
    const categoryLink = `/${category.slug}`;

    let categoryBadge = null;
    if (isPrivate(category)) {
      categoryBadge = <LockEmptyIcon className="forum-icon-fill" />;
    }

    return (
      <div className={containerClassName} style={style} data-hook="category-list-item">
        <div className={classNames(styles.details, 'forum-text-color')}>
          <div className={styles.arrow}>
            <ArrowSubcategoriesIcon className="forum-icon-fill" />
          </div>
          <div className={styles.titleWrapper}>
            <h2 className={titleClassName} data-hook="category-list-item__title">
              <Link className={styles.categoryLink} to={categoryLink}>
                {category.label}
              </Link>
            </h2>
            <p className={statisticsClassName}>
              <span>
                {t('category-list-item.total-posts', { count: category.totalPosts || 0 })}
              </span>
              {isViewsCountEnabled && showViewCount && (
                <span>
                  {t('category-list-item.total-views', { count: category.totalViews || 0 })}
                </span>
              )}
            </p>
          </div>
          <div className={styles.buttonGroup}>
            {categoryBadge}
            {doesUserHavePermissionsToSeeCategoryActions(can, category) && (
              <MoreButton size={MoreButton.SIZE_SMALL}>
                <CategoryActions category={category} />
              </MoreButton>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SubcategoryListItem.propTypes = {
  category: PropTypes.object,
  t: PropTypes.func,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  borderWidth: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  showViewCount: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = state => ({
  showViewCount: !isCacheableRendering(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withCardBorderWidth,
  withFontClassName,
  withTranslate,
  withPermissions,
)(SubcategoryListItem);
